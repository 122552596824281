<template>
    <div>
        <Nav />

        <Tittle :type="'general'">
            <h1 class="fadeInUp"><span></span>Terms and Conditions</h1>
        </Tittle>

        <section class="experts_team_area sec_pad"> 
        <div class="container">
			<div class="row">
            
				<div class="col-lg-3">
				<div class="faq_tab">
                        <h6 class="f_p t_color3 f_600 f_size_20 mb_40">Quick Navigation</h6>
                        <ul class="nav nav-tabs" id="myTab" role="tablist"> 
                            <li class="nav-item">
                                <a class="nav-link" id="student-tab" data-toggle="tab" href="#student" role="tab"
                                    aria-controls="student" aria-selected="false">Students</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="instructor-tab" data-toggle="tab" href="#instructor" role="tab"
                                    aria-controls="instructor" aria-selected="false">Instructor</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="unn-tab" data-toggle="tab" href="#unn" role="tab"
                                    aria-controls="unn" aria-selected="false">UNN</a>
                            </li> 
                        </ul>
                    </div>
 
				</div>
				<!--/aside -->

                
				<div class="col-lg-9"> 
					<div class="tab-content faq_content Cus_acc" id="myTabContent">
                        <div class="tab-pane fade show active" id="student" role="tabpanel"
                            aria-labelledby="student-tab">
                            <h3 class="f_p f_size_22 f_500 t_color3 mb_20">TERMS OF SERVICE FOR STUDENTS</h3>
                            <div id="accordion">
                                <div class="card"> 
                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                        data-parent="#accordion">
                                        <div class="card-body">
                                         <div class="postmeta">
                                            <ul>
                                                <li><i class="icon_folder-alt"></i> Collections</li>
                                                <li><i class="icon_clock_alt"></i> 01/01/2022</li>
                                                <li><i class="icon_pencil-edit"></i> Admin</li> 
                                            </ul>
                                        </div>
                                                <p>
                                    Our service is dedicated to ensuring continuous learning and education. We encourage anyone anywhere to create and share educational courses (instructors) and to enroll in these educational courses to learn (students). We consider our marketplace model the best way to offer valuable educational content to our users. We need rules to keep our platform and services safe for you, us and our student and instructor community. These Terms apply to all your activities on the FASLEARN website, our APIs and other related services (“Services”).
                                    We also provide details regarding our processing of personal data of our students and instructors in our Privacy Policy.
                                </p>
                                    <h2> 1. Accounts </h2>
                                <p>
                                    You need an account for most activities on our platform. Keep your password somewhere safe, because you’re responsible for all activity associated with your account. If you suspect someone else is using your account, let us know by contacting our Support Team. You must have reached the age of consent for online services to use this platform. You need an account for most activities on our platform, including to purchase and enroll in a course or to submit a course for publication. When setting up and maintaining your account, you must provide and continue to provide accurate and complete information, including a valid email address. You have complete responsibility for your account and everything that happens on your account, including for any harm or damage (to us or anyone else) caused by someone using your account without your permission. This means you need to be careful with your password. You may not transfer your account to someone else or use someone else’s account without their permission. If you contact us to request access to an account, we will not grant you such access unless you can provide us the login credential information for that account. In the event of the death of a user, the account of that user will be closed.
                                    You are not allowed to share your account login credential with someone else. You must notify us immediately upon learning that someone else may be using your account (or if you suspect any other breach of security) by contacting our Support Team. We may request some information from you to confirm that you are indeed the owner of your account.
                                    Students must be at least 16 years of age to create an account on FASLEARN and use the Services. If you are younger than the required age, you may not set up an account, but we encourage you to invite a parent or guardian to open an account and help you enroll in courses that are appropriate for you. If we discover that you have created an account and you are younger than the required age for consent to use online services, we will terminate your account. You can terminate your account at any time.
                                </p>   
                                    <h2> 2. Course Enrollment and Lifetime Access </h2>
                                <p>
                                    When you enroll in a course, you get a license from us to view it via the FASLEARN Services and no other use. Don’t try to transfer or resell courses in any way. We grant you a partial or lifetime access license, except when we must disable the course because of legal or policy reasons. Under our Instructor Agreement, when instructors publish a course on FASLEARN, they grant FASLEARN a license to offer a license to the course to students. This means that we have the right to sublicense the course to the students who enroll in the course. As a student, when you enroll in a course, whether it’s a free or paid course, you are getting from FASLEARN a license to view the course via the FASLEARN platform and Services, and FASLEARN is the licensor of record. Courses are licensed, and not sold, to you. This license does not give you any right to resell the course in any manner (including by sharing account information with a purchaser or illegally downloading the course and sharing it on torrent sites).
                                </p>
                                <p>
                                    In legal, more complete terms, FASLEARN grants you (as a student) a limited, non-exclusive, non-transferable license to access and view the courses and associated content for which you have paid all required fees, solely for your personal, non- commercial, educational purposes through the Services, in accordance with these Terms and any conditions or restrictions associated with a particular courses or feature of our Services. All other users are expressly prohibited. You shall not reproduce, redistribute, transmit, assign, sell, broadcast, rent, share, lend, modify, adapt, edit, create derivative works of, sublicense, or otherwise transfer or use any course unless we give you explicit permission to do so in a written agreement signed by a FASLEARN authorized representative. This also applies to content you can access via any of our APIs.
                                    We generally give a lifetime access license to our students when they enroll in a course. However, we reserve the right to revoke any license to access and use courses at any point in time in the event where we decide or are obligated to disable access to a course due to legal or policy reasons, for example, if the course you enrolled in is the object of a copyright complaint, or if we determine its content violates our Trust & Safety Guidelines. The lifetime access is not applicable to add-on features and services associated with a course, for example translation captions of courses may be disabled by instructors at any time and an instructor may decide at any time to no longer provide teaching assistance or Q&A services in association with a course. To be clear, the lifetime access is to the course content but not to the instructor.
                                    Instructors may not grant licenses to their courses to student directly and any such direct license shall be null and void and a violation of these Terms.
                                </p>
                                    <h2> 3. Payments, Credits, and Refunds </h2>
                                <p>
                                    When you make a payment, you agree to use a valid payment method. If you aren’t happy with your course, we can only make amends following feedbacks and discounts offered on next course at our discretion as all contents must pass quality checks.
                                </p>
                                    <h2> 3.1 Pricing </h2>
                                <p>
                                    The prices of courses on FASLEARN are determined based on the terms of the Instructor Agreement and our Pricing Policy. We regularly run promotions and sales for our courses and certain courses are only available at discounted prices for a set period of time. The price applicable to a course will be the price at the time you complete your purchase of the course (at checkout). Any price offered for a particular course may also be different when you are logged into your account from the price available to users who aren’t registered or logged in, because some of our promotions are available to new users only.
                                    If you are logged into your account, the listed currency you see is based on your location when you created your account. If you are not logged into your account, the price currency is based on the Nigerian Naira. We do not enable users to see pricing in other currencies. If you are a student located in a country where use and sales tax, goods and services tax, or value added tax is applicable to consumer sales, we are responsible for collecting and remitting that tax to the proper tax authorities. In certain countries, the price you see may include such taxes.
                                </p>
                                    <h2> 3.2 Payments </h2>
                                <p>
                                    You agree to pay the fees for courses that you purchase, and you authorize us to charge your debit or credit card or process other means of payment for those fees. FASLEARN works with third party payment processing partners to offer you the most convenient payment methods and to keep your payment information secure.
                                    When you make a purchase, you agree not to use an invalid or unauthorized payment method. If your payment method fails and you still get access to the course you are enrolling in, you agree to pay us the corresponding fees within thirty (10) days of notification from us. We reserve the right to disable access to any course for which we have not received adequate payments.
                                </p>  
                                    <h2> 4. Content and Behavior Rules </h2>
                                <p>
                                    You can only use FASLEARN platform for lawful purposes. You’re responsible for all the content that you post on our platform. You should keep the reviews, questions, posts, courses and other content you upload in line with our Trust & Safety Guidelines and the law and respect the intellectual property rights of others. We can ban your account for repeated or major offenses. If you think someone is infringing your copyright on our platform, let us know.
                                    You may not access or use the Services or create an account for unlawful purposes. Your use of the Services and behavior on our platform must comply with applicable local or national laws or regulations of your country. You are solely responsible for the knowledge of and compliance with such laws and regulations that are applicable to you.
                                </p>
                                <p>
                                    The Services enable you to ask questions to the instructors of courses you are enrolled in, and to post reviews of courses. For certain courses, the instructor invites you to submit content as “homework” or tests. Don’t post or submit anything that is not yours.
                                </p>
                                    <h2> 5. FASLEARN’s Rights to Content You Post </h2>
                                <p>
                                    You retain ownership of content you post to our platform. We’re allowed to share your content to anyone through any media, including promoting it via advertising on other websites.
                                    The content you post as a student remains yours. By posting contents, you allow FASLEARN to reuse and share it but you do not lose any ownership rights you may have over your content. When you post comments, questions, reviews and when you submit to us ideas and suggestions for new features or improvements, you authorize FASLEARN to use and share this content with anyone, distribute it and promote it on any platform and in any media and to make modifications or edits to it as we see fit. In legal language, by submitting or posting content on or through the platforms, you grant us a worldwide, non-exclusive, royalty-free license (with the right to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit, display, and distribute your content in any and all media or distribution methods (existing now or later developed). This includes making your content available to other companies, organizations, or individuals who partner with FMR ACADEMY for the syndication, broadcast, distribution, or publication of content on other media. You represent and warrant that you have all the rights, power, and authority necessary to authorize us to use any content that you submit. You also agree to all such uses of your content with no compensation paid to you.
                                </p>
                                    <h2> 6. Using FASLEARN at Your Own Risk </h2>
                                <p>
                                    Anyone can use FASLEARN to create and publish courses and instructors and we enable instructors and students to interact for teaching and learning. Like other platforms where people can post content and interact, some things can go wrong, and you use FASLEARN at your own risk. This also applies to any courses relating to health, wellness and physical exercise. You acknowledge the inherent risks and dangers in the strenuous nature of these types of courses, and by enrolling in such courses, you choose to assume those risks voluntarily, including risk of illness, bodily injury, disability, or death. You assume full responsibility for the choices you make before, during and after your enrollment in a course. When you interact directly with a student or an instructor, you must be careful about the types of personal information that you share. We do not control what students and instructors do with the information they obtain from other users on the platform. You should not share your email or other personal information about you for your safety. We do not hire or employ instructors nor are we responsible or liable for any interactions involved between instructors and students. We are not liable for disputes, claims, losses, injuries, or damage of any kind that might arise out of or relate to the conduct of instructors or students.
                                    When you use our Services, you will find links to other websites that we don’t own or control. We are not responsible for the content or any other aspect of these third-party sites, including their collection of information about you. You should also read their terms and conditions and privacy policies.
                                </p>
                                    <h2> 7. FASLEARN’s Rights </h2>
                                <p>
                                    We own the FASLEARN platform and Services, including the website, present or future apps and services, and things like our logos, API, code, and content created by our employees. You can’t tamper with those or use them without authorization.
                                    All right, title, and interest in and to the FASLEARN platform and Services, including our website, our existing or future applications, our APIs, databases, and the content our employees or partners submit or provide through our Services (but excluding content provided by instructors and students) are and will remain the exclusive property of FASLEARN and its licensors. Our platforms and services are protected by copyright, trademark, and other laws of both Nigeria and foreign countries. Nothing gives you a right to use the FASLEARN name or any of the FASLEARN trademarks, logos, domain names, and other distinctive brand features. Any feedback, comments, or suggestions you may provide regarding FASLEARN or the Services is entirely voluntary and we will be free to use such feedback, comments, or suggestions as we see fit and without any obligation to you.
                                    You shall not do any of the following while accessing or using the FASLEARN platform and Services:
                                    Access, tamper with, or use non-public areas of the platform, FASLEARN’s computer systems, or the technical delivery systems of FASLEARN’s service providers.
                                    Disable, interfere with, or try to circumvent any of the features of the platforms related to security or probe, scan, or test the vulnerability of any of our systems.
                                    copy, modify, create a derivative work of, reverse engineer, reverse assemble, or otherwise attempt to discover any source code of or content on the FASLEARN platform or Services. access or search or attempt to access or search our platform by any means (automated or otherwise) other than through our currently available search functionalities that are provided via our website or API (and only pursuant to those API terms and conditions). You shall not scrape, spider, use a robot, or use other automated means of any kind to access the Services. in any way use the Services to send altered, deceptive, or false source-identifying information (such as sending email communications falsely appearing as FASLEARN); or interfere with, or disrupt, (or attempt to do so), the access of any user, host, or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the platforms or services, or in any other manner interfering with or creating an undue burden on the Services.
                                </p>
                                    <h2> 8. Miscellaneous Legal Terms </h2>
                                <p>
                                    These Terms are like any other contract and they have boring but important legal terms that protect us from the countless things that could happen and that clarify the legal relationship between us and you.
                                </p>
                                    <h3> 8.1 Binding Agreement </h3>
                                <p>
                                    You agree that by registering, accessing or using our Services, you are agreeing to enter into a legally binding contract with FASLEARN. If you do not agree to these Terms, do not register, access, or otherwise use any of our Services.
                                    These Terms (including any agreements and policies linked from these Terms) constitute the entire agreement between you and us. If any part of these Terms is found to be invalid or unenforceable by applicable law, then that provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of these Terms will continue in effect.
                                    Even if we are delayed in exercising our rights or fail to exercise a right in one case, it doesn’t mean we waive our rights under these Terms, and we may decide to enforce them in the future. If we decide to waive any of our rights in a particular instance, it doesn’t mean we waive our rights generally or in the future.
                                </p>
                                    <h3> 8.2 Disclaimers </h3>
                                <p>
                                    It may happen that our platform is down, either for planned maintenance or because something goes down with the site. It may happen that one of our instructors is making misleading statements in their course. It may also happen that we encounter security issues. These are just examples. You accept that you will not have any recourse against us in any of these types of cases where things don’t work out right. In legal, more complete language, the Services and their content are provided on an “as is” and “as available” basis. We (and our affiliates, suppliers, partners, and agents) make no representations or warranties about the suitability, reliability, availability, timeliness, security, lack of errors, or accuracy of the Services or their content, and expressly disclaim any warranties or conditions (express or implied), including implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement. We (and our affiliates, suppliers, partners, and agents) make no warranty that you will obtain specific results from use of the Services. Your use of the Services (including any content) is entirely at your own risk. We may decide to cease making available certain features of the Services at any time and for any reason. Under no circumstances will FASLEARN or its affiliates, suppliers, partners or agents be held liable for any damages due to such interruptions or lack of availability of such features. We are not responsible for delay or failure of our performance of any of the Services caused by events beyond our reasonable control, like an act of war, hostility, or sabotage; natural disaster; electrical, internet, or telecommunication outage; or government restrictions.
                                </p>
                                    <h3> 8.3 Limitation of Liability </h3>
                                <p>
                                    There are risks inherent into using our Services, for example, if you enroll in a health and wellness course like yoga and you injure yourself. You fully accept these risks and you agree that you will have no recourse to seek damages against even if you suffer loss or damage from using our platform and Services. In legal, more complete language, to the extent permitted by law, we (and our group companies, suppliers, partners, and agents) will not be liable for any indirect, incidental, punitive, or consequential damages (including loss of data, revenue, profits, or business opportunities, or personal injury or death), whether arising in contract, warranty, tort, product liability, or otherwise, and even if we’ve been advised of the possibility of damages in advance. Our liability (and the liability of each of our group companies, suppliers, partners, and agents) to you or any third parties under any circumstance is limited to the amount you have paid us in the twelve (12) months before the event giving rise to your claims.
                                </p>
                                    <h3> 8.4 Indemnification </h3>
                                <p>
                                    If you behave in a way that gets us in legal trouble, we may exercise legal recourse against you. You agree to indemnify, defend (if we so request), and hold harmless FASLEARN, our group companies, and their officers, directors, suppliers, partners, and agents against any third-party claims, demands, losses, damages, or expenses (including reasonable attorney fees) arising from: the content you post or submit, (b) your use of the Services (c) your violation of these Terms, or (d) your violation of any rights of a third party. Your indemnification obligation will survive the termination of these Terms and your use of the Services.
                                </p>
                                    <h3> 8.5 Governing Law and Jurisdiction </h3>
                                <p>
                                    This Agreement shall be governed in accordance with the laws of Enugu State and the Federal Republic of Nigeria. Any action arising out of or relating to this Agreement shall be filed only in the state or federal courts located in Enugu State.
                                </p>
                                    <h3> 8.6 Legal Actions and Notices </h3>
                                <p>
                                    No action, regardless of form, arising out of or relating to this Agreement may be brought by either party more than one (1) year after the cause of action has accrued.
                                    Any notice or other communication to be given hereunder will be in writing and given by registered or certified mail return receipt requested, or email (by us to the email associated with your account or by you to our Legal or Admin Team).
                                </p>
                                    <h3> 8.7 Relationship between us </h3>
                                <p>
                                    You and we agree that no joint venture, partnership, employment, contractor, or agency relationship exists between us.
                                </p>
                                    <h3> 8.8 No Assignment </h3>
                                <p>
                                    You may not assign or transfer these Terms (or the rights and licenses granted under them). For example, if you registered an account as an employee of a company, your account cannot be transferred to another employee. We may assign these Terms (or the rights and licenses granted under them) to another company or person without restriction. Nothing in these Terms confers any right, benefit, or remedy on any third-party person or entity. You agree that your account is non-transferable and that all rights to your account and other rights under these Terms terminate upon your death.
                                </p>


                                    <h2> 9. Dispute Resolution </h2>
                                <p>
                                    If there’s a dispute, our Support Team is happy to help resolve the issue. If that doesn’t work, arbitration will be the next resort and shall be in accordance with the Arbitration and Conciliation Act, 2004. Most disputes can be resolved. Therefore, before bringing a formal legal case, please first try contacting our Support Team.
                                </p>
                                    <h2>10. Updating These Terms </h2>
                                <p>
                                    From time to time, we may update these Terms to clarify our practices or to reflect new or different practices (such as when we add new features), and FASLEARN reserves the right in its sole discretion to modify and/or make changes to these Terms at any time. If we make any material change, we will notify you using prominent means such as by email notice sent to the email address specified in your account or by posting a notice through our Services. Modifications will become effective on the day they are posted unless stated otherwise. Your continued use of our Services after changes become effective shall mean that you accept those changes. Any revised Terms shall supersede all previous Terms.
                                </p>
                                    <h2>11. How to Contact Us </h2>
                                <p>
                                    The best way to get in touch with us is to contact our Support Team. We’d love to hear your questions, concerns, and feedback about our Services.
                                </p>
                                         </div>
                                    </div>
                                </div> 
                            </div> 
                        </div>

                        <div class="tab-pane fade" id="instructor" role="tabpanel" aria-labelledby="instructor-tab">
                            <h3 class="f_p f_size_22 f_500 t_color3 mb_20">TERMS OF SERVICE FOR INSTRUCTORS</h3>
                            <div id="accordion3">
                                <div class="card"> 

                                    <div id="collapse10" class="collapse show" aria-labelledby="heading10"
                                        data-parent="#accordion3">
                                        <div class="card-body">
                                            <div class="postmeta">
                                    <ul>
                                        <li><i class="icon_folder-alt"></i> Collections</li>
                                        <li><i class="icon_clock_alt"></i> 01/01/2022</li>
                                        <li><i class="icon_pencil-edit"></i> Admin</li> 
                                    </ul>
                                </div>
                                <p>
                                    When you sign up to become an instructor on our platform, you agree to abide by these Instructor Terms (“Terms“). These Terms cover details about the aspects of our platform relevant to instructors and are incorporated by reference into our Terms of Use, the general terms that govern your use of our Services. Any capitalized terms that aren’t defined in these Terms are defined as specified in the Terms of Use.
								</p>
                                <h2>  1.1	Instructor’s Obligations </h2> 
                                <p>
                                As an instructor (referring to individuals, institutions and corporate bodies), you are responsible for all content that you post, including lectures, quizzes, coding exercises, practice tests, assignments, resources, answers, course landing page content, and announcements (“Submitted Content“).
                                </p>
                                <p>
                                    You represent and warrant that: You will provide and maintain accurate account information;
                                    You own or have the necessary licenses, rights, consents, permissions and authority to authorize FASLEARN to use your Submitted Content as specified in these Terms and the Terms of Use; Your Submitted Content will not infringe or misappropriate any third party’s intellectual property rights;
                                    You have the required qualifications, credentials, and expertise (including education, training, knowledge, and skill sets) to teach and render the services that you offer through your Submitted Content You will respond promptly to students and ensure a quality of service that corresponds with the standards of your industry and instruction services in general. Course certificates will be issued by FASLEARN only (while in the case of corporate training bodies, the certificate shall be issued in conjunction with such bodies), the instructor’s name and signature will be appended as the course trainer.
                                    FASLEARN will provide an official email address which shall and MUST be used for all communications with students and FASLEARN.
                                    You must be available to answer any student’s question(s) regarding your course.
                                    Your course is subject to deletion, if it attracts bad reviews from students.
                                </p>
                                <p>
                                    You warrant that you will not: Post or provide any inappropriate, offensive, racist, hateful, sexist, pornographic, false, misleading, incorrect, infringing, defamatory or libelous content or information. Post or transmit any unsolicited or unauthorized advertising, promotional materials, junk mail, spam, or any other form of solicitation (commercial or otherwise) through the Services or to any user.
                                    Use the Services for personal business gain other than providing tutoring, teaching and instructional services to students of FASLEARN in line with this agreement. Engage in any activity that would require us to obtain licenses from or pay royalties to any third party including the need to pay royalties for sound recording; video editing etc.  Share any document issued by FASLEARN to a third party (this includes policy documents and quality control documents).
                                    Provide exact training materials to other training/e-learning providers.
                                    Frame or embed the Services (such as to embed a free version of a course) or otherwise circumvent the Services. Impersonate another person or gain unauthorized access to another person’s account. Interfere with or otherwise prevent other instructors from providing their services or courses; or
                                    Abuse FASLEARN’s resources, including support services.
                                </p>
                                <h2> 1.2 License to FASLEARN </h2>
                                <p>
                                    You grant FASLEARN the rights detailed in the Terms of Use to offer, market and otherwise exploit your Submitted Content, and to sublicense it to students for these purposes directly or through third parties. This includes the right to add captions or otherwise modify content to ensure accessibility.
                                    Unless otherwise agreed, you have the right to remove all or any portion of your Submitted Content from the Services at any time. Except as otherwise agreed, FASLEARN’s right to sublicense the rights in this section will terminate with respect to new users 60 days after the Submitted Content’s removal.
                                </p>
                                <p>
                                    However, (1) rights given to students before the Submitted Content’s removal will continue in accordance with the terms of those licenses (including any grants of lifetime access) and (2) FASLEARN’s right to use such Submitted Content for marketing purposes shall survive termination. We may record all or any part of your Submitted Content for quality control and for delivering, marketing, promoting, demonstrating, or operating the Services. You grant FASLEARN permission to use your name, likeness, voice, and image in connection with offering, delivering, marketing, promoting, demonstrating and selling the Services, your Submitted Content, or FASLEARN’s content, and you waive any rights of privacy, publicity, or other rights of a similar nature, to the extent permissible under applicable law.
                                </p>
                                    <h2> 2.1 Content and Behaviour Rules </h2>
                                <p>
                                    You can submit courses for publication on the platform and you can also communicate with the students who have enrolled in your courses. In both cases, you must abide by the law and respect the rights of others: you cannot post any course, question, answer, review or other content that violates applicable local or national laws or regulations of your country. You are solely responsible for any courses, content, and actions you post or take via the platform and Services and their consequences. Make sure you understand all the copyright restrictions set forth in the Instructor Agreement before you submit any course for publication on FASLEARN. If we are put on notice that your course or content violates the law or the rights of others (for example, if it is established that it violates intellectual property or image rights of others, or is about an illegal activity), if we discover that your content or behavior violates our Trust & Safety Guidelines, or if we believe your content or behavior is unlawful, inappropriate, or objectionable (for example if you impersonate someone else), we may remove your content from our platform.
                                </p>
                                    <h2>   2.2 Trust & Safety </h2>
                                <p>
                                    You understand that your use of the Services is subject to FASLEARN’s approval, which we may grant or deny at our sole discretion. We reserve the right to remove courses, suspend payouts, and/or ban instructors for any reason at any time, without prior notice, including in cases where: an instructor or course does not comply with our policies or legal terms (including the Terms of Use); a course falls below our quality standards or has a negative impact on the student experience; an instructor engages in behavior that might reflect unfavorably on FASLEARN or bring FASLEARN into public disrepute, contempt, scandal, or ridicule; an instructor engages the services of a marketer or other business partner who violates FASLEARN’s policies; or
                                    uses platform for personal gain or tries to recruit or communicate students outside the FASLEARN platform As determined by FASLEARN in its sole discretion.
                                </p>
                                    <h2>   3.1 Relationship to Other Users </h2>
                                <p>
                                    Instructors don’t have a direct contractual relationship with students, so the only information you’ll receive about students is what is provided to you through the Services. You agree that you will not use the data you receive for any purpose other than providing your services to those students on the FASLEARN platform and that you won’t solicit additional personal data or store students’ personal data outside the FASLEARN platform. You will indemnify FASLEARN against any claims arising from your use of students’ personal data.
                                </p>
                                    <h2>    3.2 Anti-Piracy Efforts </h2>
                                <p>
                                    Kindly ensure you take every legitimate step to protect your courses. Such steps shall be in compliance with all relevant intellectual property laws governing your location.
                                </p>
                                    <h2> 4. Pricing </h2>
                                    <h3> 4.1 Price Setting </h3>
                                <p>
                                    Instructors will set prices for their courses, however FASLEARN reserves the right to offer alternative prices which should be considered following valid reason(s) such as the instruction given below.
                                    Kindly note that courses offered by the instructor must be cheaper on FASLEARN than any other platform. The FASLEARN Deals Program helps increase your revenue potential by enabling FASLEARN to offer your course at a compelling discount as part of targeted promotions.
                                    You may choose to participate in the Deals Program. Percentage Promotions allows FASLEARN to offer your course for a discount of up to 75% off the Base Price.
                                </p>
                                    <h3> 4.2 Transaction Taxes </h3>
                                <p>
                                    If a student purchases a product or service in a country that requires FASLEARN to remit national, state, or local sales or use taxes, value added taxes (VAT), or other similar transaction taxes (“Transaction Taxes“), under applicable law, we will collect and remit those Transaction Taxes to the competent tax authorities for those sales. We may increase the sale price at our discretion where we determine that such taxes may be due.
                                    Payments
                                </p>
                                   <h3>  5.1 Revenue Share </h3>
                                <p>
                                    When a student purchases your course, we calculate the gross amount of the sale as the amount actually received by FASLEARN from the student (“Gross Amount”). From this, Your revenue share will be 30% (while that of corporate training bodies shall be 50%). If we change this payment rate, we will provide you 30 days’ notice using prominent means, such as via email or by posting a notice through our Services. FASLEARN makes all instructor payments in Naira regardless of the currency with which the sale was made. We will assume transaction processing fees, Transaction Taxes i.e. VAT, any mobile platform fees applied to mobile application sales, excluding foreign currency conversion fees and wiring fees. Your revenue report will show the sales price (in local currency).
                                </p>
                                    <h3> 5.2 Receiving Payments </h3>
                                <p>   
                                    For us to pay you in a timely manner, you must own a bank or Paystack account in good standing and must keep us informed of the correct email associated with your account. You understand and agree that you are ultimately responsible for any taxes on your income. You also accept any charges from the payment gateway provider.
                                    Payment will be made immediately we receive the fee for a course or (b) the relevant course consumption occurred. For courses over N15,000, students are allowed to pay via 2 installments. 50% before enrollment and 50% before completion, with this model, you will receive payments as paid by the student.
                                    We reserve the right not to pay out funds in the event of identified fraud, violations of intellectual property rights, or other violations of the law.
                                    If we cannot settle funds into your payment account after the period of time set forth by your state, country, or other government authority in its unclaimed property laws, we may process the funds due to you in accordance with our legal obligations, including by submitting those funds to the appropriate government authority as required by law.
                                </p>
                                    <h2> 6.1 Trademarks </h2>
                                <p>
                                    Ensure that the videos, audios and slides would have and contain the FASLEARN trademark on them. While you are a published instructor and subject to the requirements below, you may use our trademarks where we authorize you to do so. You must: only use the images of our trademarks that we make available to you, as detailed in any guidelines we may publish;
                                    only use our trademarks in connection with the promotion and sale of your FASLEARN courses or your participation on FASLEARN and Immediately comply if we request that you discontinue use.
                                </p>
                                <p>
                                    You must not: use our trademarks in a misleading or disparaging way;
                                    use our trademarks in a way that implies that we endorse, sponsor, or approve of your courses or services or Use our trademarks in a way that violates applicable law or in connection with an obscene, indecent, or unlawful topic or material.
                                </p>
                                    <h2> 7.1 Deleting Your Account </h2>
                                <p>
                                    We’ll use commercially reasonable efforts to make any remaining scheduled payments that are owed to you before deleting your account. You understand that if students have previously enrolled in your courses, your name and that Submitted Content will remain accessible to those students after your account is deleted. If you need help or encounter difficulty deleting your account, you can contact us via our Support Center. Miscellaneous Legal Terms
                                </p>
                                    <h2> 8.1 Updating These Terms </h2>
                                <p>
                                    From time to time, we may update these Terms to clarify our practices or to reflect new or different practices (such as when we add new features), and FASLEARN reserves the right in its sole discretion to modify and/or make changes to these Terms at any time. If we make any material change, we will notify you using prominent means such as by email notice sent to the email address specified in your account or by posting a notice through our Services. Modifications will become effective on the day they are posted unless stated otherwise.
                                    Your continued use of our Services after changes become effective shall mean that you accept those changes. Any revised Terms shall supersede all previous Terms.
                                </p>
                                    <h2> 8.3 Relationship Between us </h2>
                                <p>
                                    You and we agree that no joint venture, partnership, employment, contractor, or agency relationship exists between us.
                                    How to Contact us
                                    The best way to get in touch with us is to contact our Support Team. We’d love to hear your questions, concerns, and feedback about our Services.
                                </p> 
                                <p>   
                                    Copyright © 2020 Faslearn
                                </p>
                                         </div>
                                    </div>
                                </div> 
                            </div>
                        </div>  

                        <div class="tab-pane fade" id="unn" role="tabpanel" aria-labelledby="unn-tab">
                            <h3 class="f_p f_size_22 f_500 t_color3 mb_20">TERMS OF USE FOR COURSE DEVELOPMENT FOR UNIVERSITY OF NIGERIA</h3>
                            <div id="accordion4">
                                <div class="card"> 
                                    <div id="collapse15" class="collapse show" aria-labelledby="heading15"
                                        data-parent="#accordion4">
                                        <div class="card-body">
                                        <div class="postmeta">
                                    <ul>
                                        <li><i class="icon_folder-alt"></i> Collections</li>
                                        <li><i class="icon_clock_alt"></i> 01/01/2022</li>
                                        <li><i class="icon_pencil-edit"></i> Admin</li>
                                        <li><i class="icon_comment_alt"></i></li>
                                    </ul>
                                </div>
                                <p>
                                This Course Development Agreement (“Agreement”) is between <br>

                                The University of Nigeria Consultancy Services Limited, a company incorporated under the laws of the Federal Republic of Nigeria and having its registered office at (provide address) (hereinafter referred to as “The University” which expression shall wherever the context so permits include its successors- in-title, subsidiaries, affiliates and assigns) of the one  part.
                                <br>
                                AND
                                <br>
                                You the Developer (hereinafter referred to as the “Developer”) which expression shall wherever the context so permits include his heirs, successors- in-title, and assigns of the other  part.
                                </p>
                                <p>
                                The University and the Developer may each be referred to herein solely as a “Party” or jointly as the “Parties” as the case may be.
                                </p>
                                <p>
                                WHEREAS:
                                </p>
                                <p>
                                1.	The Developer has been selected by The University to assist in the creation and development of a certain course as specifically set forth in this Agreement in furtherance of knowledge sharing, including but not limited to a syllabus including learning outcomes, course content, assignments and other assessments of student learning, course materials and resources, and sample evaluation criteria including assessments which enable evaluation of integrative thinking and other General Education objectives addressed in the course (collectively, the “Work”);
                                <br>
                                2.	The Developer desires to create and develop the Work for the University as a University-directed work so that it may be available to the University to advance the University’s educational mission.
                                <br>
                                3.	The University and the Developer recognize the mutual benefits to be derived from the creation of the approved work and the necessity for the faithful performance of the terms and conditions of this agreement. 
                                </p>
                                <p>
                                NOW, THEREFORE, in consideration of the foregoing premise, mutual promises, covenants, and undertakings set forth below and for other good and valuable consideration, the receipt and sufficiency of which is hereby acknowledged, the Parties hereby agree as follows.
                                </p>
                                <p>
                                1. Services. Subject to the terms and conditions of this Agreement, the Developer is selected by The University: (1) to create for approval, a learning course in accordance with the University requirements and provide Content, (2) to assist in the integration of Content into the design and format of the Work.  A set of the guidelines for tasks to be performed by the Developer shall be provided by the University. As used in this Agreement, the term “work” includes, without limitation, all materials submitted to the University in accordance with this Agreement including but not limited to videotapes, audio tapes, text, graphics, study guides, syllabi, tests, study aids of any type, written protocols, outlines, drafts, articles or other literary work in any format, including paper, electronic, computer-readable, machine-readable, CD-ROM, sound or video recording. 
                                <br>
                                2. Ownership of the Work & Payment.  As agreed,  the courses developed by you shall be payable after the certification and approval of the work by the University, the Developer acknowledges and agrees that the Work is created at the direction of the University and shall be considered a University-directed work for the University’s ownership and use.”
                                <br>
                                2.1The Parties acknowledge and agree that the University retains ownership of the Content.
                                <br>
                                2.2The University hereby retains, and the Developer hereby grants, the University a perpetual, non-exclusive, royalty-free right to use, reproduce, and prepare derivative works, adaptions and versions of the Work for the University’s own use.  
                                <br>
                                2.3Except as set forth in Paragraphs 2.1 and 2.2, the Developer hereby fully, unconditionally, and irrevocably transfers, assigns, delivers, and conveys to the University all of the Developer’s worldwide right, title, and interest, in and to the Work including without limitation, any and all enhancements, modifications, derivative works, and components of any of the foregoing developed by or for the University, including (a) all copyrights, copyright applications, and registrations, and any renewals or extensions thereto; (b) any and all moral rights; (c) works of authorship (copyrightable and non-copyrightable); (d) all other proprietary or intellectual property rights of any kind or nature therein; and (e) all of the assets, properties, contracts, rights, and obligations relating thereto, including the right to recover for damages and profits and any and all other remedies for infringement, which may have occurred before the date of this Agreement, the same to be held and enjoyed by the University, for its own use and benefit, and for its legal representatives and assigns, to the full end of the term for which said rights are granted, as fully and entirely as the same would have been held by the Developer had this Agreement not been made. The Developer acknowledges that the restrictions contained herein are reasonable and necessary and that any violation of these restrictions would cause substantial injury to the University. In the event of any material violation of this Agreement by the Developer, the University shall retain the right to seek money damages, preliminary and permanent injunctive relief, and/or any other remedies at law against the Developer. 
                                <br>
                                3. Representations and Warranties.  The Developer represents and warrants that the Work is the Developer’s original work.  To the best of the Developer’s knowledge, the Work does not infringe upon the rights of any third party and hereby agrees to indemnify and save the University from all claims, demands, actions, causes of action, suits, liabilities, costs, proceedings, damages, losses or injury of any kind arising from the use of the Work provided by the Developer. 
                                <br>
                                4.  Confidentiality:  The Developer acknowledges that during the development of the Work, he or she may receive during the course of performing the Work, or be exposed to certain confidential and proprietary information about the University, its students and/or programs (collectively, the “Confidential or Proprietary Information”).  The Developer shall hold confidential and shall not, disclose, publish, or use for the benefit of itself or any third party, any Confidential or Proprietary Information.  As used in herein, the term “Confidential or Proprietary Information” shall mean and refer to any and all information disclosed to, or otherwise acquired or observed by the Developer or his/her employees or agents, if any, concerning the University or its officers, directors, trustees alumni or students or its programs including all but not limited any marketing plans, business models or plans, research study data or conclusions, work processes, data, academic or personal information, forms, results, methods, processes, compensation, trade secrets, know-how, customer lists, details of contracts, and pricing policies. This restriction shall not apply if the Confidential or Proprietary Information: (a) was known by the Developer prior to receipt from the University; (b) which is generally available to the public; (c) which is lawfully acquired from third parties who have a right to disclose such information.  
                                <br>
                                5.  Construction.  This Agreement is governed by laws of  Nigeria  and constitutes the entire agreement between the Parties and supersedes any oral or written agreement, understanding, discussion, or other documentation to the contrary with regards to the subject matter herein.
                               <br>     
                                6.  Dispute Resolution:<br>
                                Any dispute arising or relating to this Agreement shall be resolved by mutual consultation and consent of the Parties hereto. Any dispute which cannot within a period of 30 working days be mutually resolved by the Parties in accordance with the above Clause shall be referred to a single arbitrator mutually agreed by both Parties for determination in accordance with the Arbitration and Conciliation Act Cap A18 Laws of the Federation of Nigeria 2004 or any amendment made thereto and judgment upon the award rendered by the arbitrator may be entered in any state having jurisdiction. The venue of the arbitration shall be Nsukka, Enugu, Nigeria.
                                </p>
 										</div>
                                    </div>
                                </div> 
                            </div> 
                        </div>
                        </div>
                        </div>
 
			</div>
			<!-- /row -->
		</div>
		<!-- /container -->
        </section>
        <Footer />
    </div>
</template>

<script>
    import Nav from '@/components/site/Nav.vue'
    import Tittle from '@/components/site/Tittle.vue'
    import Footer from '@/components/site/Footer.vue'
    export default {
        name: 'Blogpost',
        components: {
            Nav,
            Tittle,
            Footer
        },
        mounted(){
            let js = document.createElement('script')
            js.setAttribute('src', 'js/main.js')
            document.head.appendChild(js)
            let jsc = document.createElement('script')
            jsc.setAttribute('src', 'js/common_scripts.js')
            document.head.appendChild(jsc)
        }
    }
</script>

 
<style scoped>

	@import url("./asset/css/bootstrap.min.css"); 
	@import url("./asset/vendors/animation/animate.css"); 
	@import url("./asset/css/style.css");
	@import url("./asset/css/responsive.css"); 
 /* img{
	 border-radius: 10px;
 } */
</style>